<template>
  <div>
    <a-card :tab-list="tabList" :active-tab-key="currentTab" @tabChange="tabChange">
      <div v-if="currentTab === 'PendingInspectionTaskPanel'">
        <PendingInspectionTaskPanel />
      </div>
      <div v-if="currentTab === 'PendingInspectionRecordPanel'">
        <PendingInspectionRecordPanel />
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  components: {
    PendingInspectionTaskPanel: () => import("./PendingInspectionTaskPanel"),
    PendingInspectionRecordPanel: () => import("./PendingInspectionRecordPanel"),
  },
  data() {
    return {
      tabList: [
        {
          key: "PendingInspectionTaskPanel",
          tab: "待检任务",
        },
        {
          key: "PendingInspectionRecordPanel",
          tab: "检验记录",
        },
      ],
      currentTab: undefined,
    };
  },
  methods: {
    initData() {
      let currentTab = this.$route.query.currentTab;
      if (currentTab) {
        this.currentTab = currentTab;
      } else {
        this.currentTab = this.tabList[0].key;
        this.$router.push({ query: { currentTab: this.currentTab } });
      }
    },
    tabChange(key) {
      this.currentTab = key;
      this.$router.push({ query: { currentTab: this.currentTab } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
